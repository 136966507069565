<template>
  <div class="video-js-responsive-container vjs-hd">
    <video
      :ref="hdvideoplayer"
      class="video-js"
      controls
      preload="none"
      :width="vwidth"
      :height="vheight"
      :poster="poster"
      data-setup="{}"
    >
      <source :src="source" label="" type="application/x-mpegURL" />
    </video>
  </div>
</template>

<style>
@import "https://unpkg.com/video.js@7.3.0/dist/video-js.min.css";
.video-js-responsive-container.vjs-hd {
  padding-top: 56.25%;
}

.video-js-responsive-container.vjs-sd {
  padding-top: 75%;
}

.video-js-responsive-container {
  width: 100%;
  position: relative;
  float: left;
}

.video-js-responsive-container .video-js {
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}
</style>

<script>
import videojs from "video.js";
export default {
  beforeDestroy() {
   if (this.player) {
      this.player.dispose();
    }
  },
  props: {
    source: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
      required: false,
      default: "https://dev.bhrt.ba/er/images/stream.jpg",
    },
    vwidth: {
      type: String,
      required: false,
      default: "900",
    },
    vheight: {
      type: String,
      required: false,
      default: "506",
    },
    hdvideoplayer: {
      type: String,
      required: false,
      default: "hdVideoPlayer",
    },
  },
  data() {
    return {
      player: null,
    };
  },
  beforeUpdate() {
    console.log("before update");
    if (this.player) {
      console.log("ima player", this.player);
      let new_source = { src: this.source, type: "application/x-mpegURL" };
      this.player.src(new_source);
    }
  },
  mounted() {
    var css =
        ".video-js .vjs-time-tooltip{top: -3.9em;}.video-js .vjs-control:focus, .video-js .vjs-control:focus:before, .video-js .vjs-control:hover:before{text-shadow: 0 0 0em #fff;}.vjs-menu li.vjs-menu-item:focus,.vjs-menu li.vjs-menu-item:hover{outline: none}.video-js.vjs-fullscreen{font-size:18px}.video-js button{outline: none}.video-js .vjs-controls-disabled .vjs-big-play-button{display: none!important}.vjs-paused.vjs-has-started.video-js .vjs-big-play-button,.video-js.vjs-ended .vjs-big-play-button,.video-js.vjs-paused .vjs-big-play-button{display: block}.video-js .vjs-load-progress div,.vjs-seeking .vjs-big-play-button,.vjs-waiting .vjs-big-play-button {display: none!important}.video-js.vjs-ended .vjs-big-play-button{display: block!important}.video-js .vjs-big-play-button{top: 50%;left: 50%;margin-left: -1em;margin-top: -1em;width: 2em;height: 2em;line-height: 2em;border: none;border-radius: 50%;font-size: 3.5em;background-color: rgba(0, 0, 0, .45);color: #fff;-webkit-transition: border-color .4s, outline .4s, background-color .4s;-moz-transition: border-color .4s, outline .4s, background-color .4s;-ms-transition: border-color .4s, outline .4s, background-color .4s;-o-transition:border-color .4s, outline .4s, background-color .4s;transition: border-color .4s, outline .4s, background-color .4s}.video-js .vjs-big-play-button{background-color: rgba(0, 0, 0, 0);font-size: 12em;border-radius: 0%;height: 1em!important;line-height: 1em!important;margin-top: -0.5em!important}.video-js:hover .vjs-big-play-button,.video-js .vjs-big-play-button:focus,.video-js .vjs-big-play-button:active{background-color: rgba(0, 0, 0, 0)}.video-js .vjs-remaining-time-display{display: none}.video-js .vjs-duration{display: block;left: 0em}.video-js .vjs-current-time{display: block;left: 0}.vjs-live .vjs-time-control{display: none}",
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");
    style.type = "text/css";
    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    head.appendChild(style);
    videojs.options.controlBar = {
      volumePanel: { inline: !1, vertical: !0 },
      children: [
        "playToggle",
        "volumePanel",
        "liveDisplay",
        "currentTimeDisplay",
        "progressControl",
        "durationDisplay",
        "chaptersButton",
        "descriptionsButton",
        "subsCapsButton",
        "audioTrackButton",
        "fullscreenToggle",
      ],
    };

    this.player = videojs(
      this.$refs[this.hdvideoplayer],
      this.options,
      function onPlayerReady() {
        //console.log("onPlayerReady", this);
      }
    );
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>